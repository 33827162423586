var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-calendar-star")]),_vm._v(" Tahakkuk Analiz ")],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("helper_text.analysis.assessment"))+" ")]),(_vm.clusterId)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-form',{ref:"form",staticClass:"mb-3",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex align-items-center justify-content-end mt-3",attrs:{"sm":"12"}},[_c('v-btn',{attrs:{"small":"","color":"pink","outlined":""},on:{"click":function($event){return _vm.exportXLSX()}}},[_c('v-icon',[_vm._v("mdi-file-table")]),_vm._v(" Dışa Aktar ")],1)],1)],1)],1)],1)],1),_c('v-simple-table',{attrs:{"dense":"","id":"printTable"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"bg-secondary bordered"},[_c('th',{staticClass:"text-left sticky bg-secondary"},[_vm._v("Tür")]),_c('th',[_vm._v("Yıl")]),_c('th',[_vm._v("Ay")]),_c('th',[_vm._v("Ana Para")]),_c('th',[_vm._v("Gecikme")]),_c('th',[_vm._v("Ödenen Ana Para")]),_c('th',[_vm._v("Ödenen Gecikme")]),_c('th',[_vm._v("Kalan Ana Para")]),_c('th',[_vm._v("Kalan Gecikme")]),_c('th',[_vm._v("Başarı")]),_c('th',[_vm._v("İcra")]),_c('th',[_vm._v("İcra Tahsilat")]),_c('th',[_vm._v("İcra Kalan")]),_c('th',[_vm._v("İcra Başarı")])])]),_c('tbody',_vm._l((_vm.list),function(row,index){return _c('tr',{key:row.id},[(
                index === 0 ||
                _vm.list[index - 1].income_type_id !== row.income_type_id
              )?_c('td',{staticClass:"bg-secondary",attrs:{"rowspan":_vm.list.filter((v) => v.income_type_id === row.income_type_id)
                  .length}},[_vm._v(" "+_vm._s(row.income_type_name)+" ")]):_vm._e(),(
                index === 0 ||
                _vm.list[index - 1].income_type_id !== row.income_type_id ||
                _vm.list[index - 1].year !== row.year
              )?_c('td',{staticClass:"bg-secondary",attrs:{"rowspan":_vm.list.filter(
                  (v) =>
                    v.income_type_id === row.income_type_id &&
                    v.year === row.year
                ).length}},[_vm._v(" "+_vm._s(row.year)+" ")]):_vm._e(),_c('td',{staticClass:"bg-secondary text-end"},[_vm._v(_vm._s(row.month))]),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.deferment_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.collected_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.collected_deferment}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.remaining_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.remaining_deferment}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"percentage":"","value":row.success_percent}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.enforcement_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.enforcement_collecting_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"price":"","muted-zeros":"","value":row.enforcement_remaining_amount}})],1),_c('td',{staticClass:"text-end"},[_c('rs-table-cell-number',{attrs:{"muted-zeros":"","percentage":"","value":row.enforcement_success_percent}})],1)])}),0),_c('tfoot')]},proxy:true}],null,false,2037837004)})],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
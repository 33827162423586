<template>
  <v-card>
    <v-card-title>
      <v-icon>mdi-calendar-star</v-icon>
      Tahakkuk Analiz
    </v-card-title>
    <v-card-subtitle>
      {{ $t("helper_text.analysis.assessment") }}
    </v-card-subtitle>
    <v-card-text v-if="clusterId">
      <v-row dense>
        <v-col>
          <v-form
            ref="form"
            @submit.prevent="handleFormSubmit"
            class="mb-3"
            :disabled="isLoading"
          >
            <v-row cols="12">
              <!-- <v-col sm="2">
                <rs-datepicker
                  label="Başlangıç Tarih"
                  v-model="formData.start_date"
                  :rules="[
                    rules.maxDate(formData.start_date, formData.end_date),
                  ]"
                />
              </v-col>
              <v-col sm="2">
                <rs-datepicker
                  label="Bitiş Tarihi"
                  v-model="formData.end_date"
                  :rules="[
                    rules.minDate(formData.end_date, formData.start_date),
                  ]"
                />
              </v-col>
              <v-col sm="2">
                <rs-select-provider v-model="formData.provider_id" />
              </v-col>
              <v-col sm="2">
                <rs-select-project v-model="formData.project_id" />
              </v-col>
              <v-col sm="2">
                <v-switch
                  v-model="formData.is_active"
                  label="Gider Tiplerini Göster"
                  hide-details="auto"
                ></v-switch>
              </v-col>
              <v-col sm="2">
                <rs-form-buttons
                  :is-loading="isLoading"
                  @submit="handleFormSubmit"
                  hide-cancel
                  hide-save-close
                  saveLabel="Filtrele"
                />
              </v-col> -->
              <v-col
                sm="12"
                class="d-flex align-items-center justify-content-end mt-3"
              >
                <v-btn small color="pink" outlined @click="exportXLSX()">
                  <v-icon>mdi-file-table</v-icon>
                  Dışa Aktar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-simple-table dense id="printTable">
        <template v-slot:default>
          <thead>
            <tr class="bg-secondary bordered">
              <th class="text-left sticky bg-secondary">Tür</th>
              <th>Yıl</th>
              <th>Ay</th>
              <th>Ana Para</th>
              <th>Gecikme</th>
              <th>Ödenen Ana Para</th>
              <th>Ödenen Gecikme</th>
              <th>Kalan Ana Para</th>
              <th>Kalan Gecikme</th>
              <th>Başarı</th>
              <th>İcra</th>
              <th>İcra Tahsilat</th>
              <th>İcra Kalan</th>
              <th>İcra Başarı</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in list" :key="row.id">
              <td
                class="bg-secondary"
                v-if="
                  index === 0 ||
                  list[index - 1].income_type_id !== row.income_type_id
                "
                :rowspan="
                  list.filter((v) => v.income_type_id === row.income_type_id)
                    .length
                "
              >
                {{ row.income_type_name }}
              </td>
              <td
                class="bg-secondary"
                v-if="
                  index === 0 ||
                  list[index - 1].income_type_id !== row.income_type_id ||
                  list[index - 1].year !== row.year
                "
                :rowspan="
                  list.filter(
                    (v) =>
                      v.income_type_id === row.income_type_id &&
                      v.year === row.year
                  ).length
                "
              >
                {{ row.year }}
              </td>
              <td class="bg-secondary text-end">{{ row.month }}</td>
              <td class="text-end">
                <rs-table-cell-number price muted-zeros :value="row.amount" />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.deferment_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.collected_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.collected_deferment"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.remaining_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.remaining_deferment"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number percentage :value="row.success_percent" />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.enforcement_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.enforcement_collecting_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  price
                  muted-zeros
                  :value="row.enforcement_remaining_amount"
                />
              </td>
              <td class="text-end">
                <rs-table-cell-number
                  muted-zeros
                  percentage
                  :value="row.enforcement_success_percent"
                />
              </td>
            </tr>
            <!-- <tr v-for="row in expenseTypeList" :key="row.id" class="bordered">
              <td
                class="bg-secondary sticky"
                v-if="row.rowspan !== 0"
                :rowspan="row.rowspan"
              >
                {{ row.expense_type_name }}
              </td>
              <td v-for="cell in row.cells" :key="cell.date" class="text-end">
                <rs-table-cell-number
                  price
                  :value="cell.amount"
                  :class="!cell.amount ? 'text-muted' : ''"
                />
              </td>
              <td class="bg-secondary">
                <rs-table-cell-number
                  price
                  :value="
                    totals.expense_type_sums.filter(
                      (v) => v.expense_type_id === row.id
                    )[0].sum
                  "
                />
              </td>
            </tr> -->
          </tbody>
          <tfoot>
            <!-- <tr class="bg-secondary bordered">
              <td class="font-weight-bold bg-secondary sticky">Genel Toplam</td>
              <td class="text-right" v-for="cell in headerList" :key="cell.key">
                <rs-table-cell-number price :value="cell.sum" />
              </td>
              <td class="bg-secondary bordered">
                <rs-table-cell-number price :value="totals.sum" />
              </td>
            </tr> -->
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { filtersToURL, hasExport, hasForm } from "@/view/mixins";
import { utils, writeFile } from "xlsx";

export default {
  name: "AssessmentAnalysis",
  mixins: [filtersToURL, hasExport, hasForm],
  computed: {
    ...mapGetters(["clusterId", "monthList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    exportXLSX() {
      try {
        const element = document.getElementById("printTable");
        const ws = utils.table_to_sheet(
          element.getElementsByTagName("TABLE")[0]
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Export");
        return writeFile(wb, "Robosay - Tahakkuk Analiz.xlsx");
      } catch (e) {
        this.handleError(e);
      }
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        this.list = [];
        return;
      }

      this.setURLParams();
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      // params.payed_on = {
      //   min: this.formData.start_date || undefined,
      //   max: this.formData.end_date || undefined,
      // };

      // if (this.formData.is_active) {
      //   params.child_expenses_enabled = 1;
      // }

      // params.per_page = 100;

      return params;
    },
    loadList() {
      if (!this.clusterId) {
        return false;
      }

      const params = this.getParams();
      this.isLoading = true;
      this.expenseTypeList = [];
      this.headerList = [];
      this.monthDataList = [];
      this.totals = {};
      this.expenseTypeSums = [];
      this.yearList = [];

      this.list = [];

      this.$api
        .query("income-analysis/assessments/" + this.clusterId, { params })
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.childExpensesEnabled = false;
            this.list = response.data.data;
            // this.loadListMain(response.data);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>

<style>
tr.bordered th,
tr.bordered td {
  border: 1px solid #0000001f;
}

tr > td.sticky,
tr > th.sticky {
  border: 1px solid #0000001f !important;
  left: 0;
  position: sticky;
}

tr > td.sticky.sticky-top,
tr > th.sticky.sticky-top {
  top: 0;
}
</style>
